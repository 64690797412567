import React, {useEffect, useState} from "react";
import {I18nextContext, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import Question from "../../components/quiz/Question";
import {StaticImage} from "gatsby-plugin-image";
import {validateBusinessEmail} from '../../utils/utils';


import aspireThumb from './images/aspire.png';
import aspireThumbLarge from './images/aspire-large.png';
import cleoThumb from './images/cleo.png';
import cleoThumbLarge from './images/cleo-large.png';
import curveThumb from './images/curve.png';
import curveThumbLarge from './images/curve-large.png';
import orbitThumb from './images/orbit.png';
import orbitThumbLarge from './images/orbit-large.png';
import pivotThumb from './images/pivot.png';
import pivotThumbLarge from './images/pivot-large.png';
import versaThumb from './images/versa.png';
import versaThumbLarge from './images/versa-large.png';
import blueCheck from '../../images/shared/circle-check.svg';
import circleX from '../../images/shared/circle-x-quiz.svg';
import dummyImage from './images/dummy.png';
import hubspotSettings from "../../constants/company/settings";
import {isInnerWidthMobile} from "../../utils/utils";


const IndexPage = () => {
  const {t} = useTranslation();
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const questions = [
    {
      id: 1,
      question: t('q_one'),
      options: [
        {answer: t('q_one_answer_one'), answerCount: 1},
        {answer: t('q_one_answer_two'), answerCount: 2},
        {answer: t('q_one_answer_three'), answerCount: 3}
      ],
    },
    {
      id: 2,
      question: t('q_two'),
      options: [
        {answer: t('q_two_answer_one'), answerCount: 1},
        {answer: t('q_two_answer_two'), answerCount: 2}
      ],
    },
    {
      id: 3,
      question: t('q_three'),
      options: [
        {answer: t('q_three_answer_one'), answerCount: 1},
        {answer: t('q_three_answer_two'), answerCount: 2},
      ],
    },
    {
      id: 4,
      question: t('q_four'),
      options: [
        {answer: t('q_four_answer_one'), answerCount: 1},
        {answer: t('q_four_answer_two'), answerCount: 2},
        {answer: t('q_four_answer_three'), answerCount: 3}
      ],
    },
    {
      id: 5,
      question: t('q_five'),
      options: [
        {answer: t('q_five_answer_one'), answerCount: 1},
        {answer: t('q_five_answer_two'), answerCount: 2},
        {answer: t('q_five_answer_three'), answerCount: 3},
        {answer: t('q_five_answer_four'), answerCount: 4},
        {answer: t('q_five_answer_five'), answerCount: 5},
      ],
    },
    {
      id: 6,
      question: t('q_six'),
      options: [
        {answer: t('q_six_answer_one'), answerCount: 1},
        {answer: t('q_six_answer_two'), answerCount: 2},
      ],
    },
  ];
  const [questionCount, setQuestionCount] = useState(questions.length);
  const [answers, setAnswers] = useState({});
  const [currentStep, setCurrentStep] = useState(0); //0 is for hero
  const [covererStep, setCovererStep] = useState(13); //width of ellipses in px
  const [aspireScore, setAspireScore] = useState(0);
  const [cleoScore, setCleoScore] = useState(0);
  const [curveScore, setCurveScore] = useState(0);
  const [orbitScore, setOrbitScore] = useState(0);
  const [pivotScore, setPivotScore] = useState(0);
  const [versaScore, setVersaScore] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [accessNumber, setAccessNumber] = useState(0);
  const [powerNumber, setPowerNumber] = useState(0);
  const [mechanismNumber, setMechanismNumber] = useState(0);


  const c = (e) => {

    const postSubmit = hubspotSettings.CONTACT_FORM_QUIZ;
    // const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    // const pageUri = isBrowser ? window.location.href : null;
    // const pageName = isBrowser ? document.title : null;
    const body = {

      fields: [
        {
          name: 'email',
          value: email,
        },
      ]
    }

    fetch(postSubmit, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
    })
        // .then(res => res.json())
        .then((res) => {
          setFormSubmitted(true);
          setTimeout(() => {
            showResults();
          }, 1000);
        })
        .catch(err => {
          alert(err);
        })
  };
  const [activeQuizClass, setActiveQuizClass] = useState(0);
  const quizClasses = ['questions intro', 'questions', 'questions form', 'questions outro'];
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [lockList, setLockList] = useState(
      [
        {
          'lock': 'Aspire',
          'score': 0,
          'image': aspireThumb,
          'url': '/products/smart-locks/aspire/',
          'imageLarge': aspireThumbLarge,
          'access': 'Keypad (code),RFID badge or fob,Smartphone / Keypad,Smartphone / RFID',
          'power': 'Battery',
          'locking': 'Motorized',
          'accessIndexes': '2,3,4,5',
          'powerIndexes': '2',
          'mechanismIndexes': '1'
        },
        {
          'lock': 'Cleo',
          'score': 0,
          'image': cleoThumb,
          'url': '/products/mechanical-locks/cleo/',
          'imageLarge': cleoThumbLarge,
          'access': 'Numeric Dials',
          'power': 'Mechanical (Battery-Free)',
          'locking': 'Manual',
          'accessIndexes': '1',
          'powerIndexes': '1',
          'mechanismIndexes': '2'
        },
        {
          'lock': 'Curve',
          'score': 0,
          'image': curveThumb,
          'url': '/products/smart-locks/curve/',
          'imageLarge': curveThumbLarge,
          'access': 'Keypad (code),RFID badge or fob,Smartphone / Keypad,Smartphone / RFID',
          'power': 'Battery',
          'locking': 'Motorized',
          'accessIndexes': '2,3,4,5',
          'powerIndexes': '2',
          'mechanismIndexes': '1'
        },
        {
          'lock': 'Orbit',
          'score': 0,
          'image': orbitThumb,
          'url': '/products/smart-locks/orbit/',
          'imageLarge': orbitThumbLarge,
          'access': 'RFID badge or fob,Smartphone / RFID',
          'power': 'Battery',
          'locking': 'Motorized',
          'accessIndexes': '3,5',
          'powerIndexes': '2',
          'mechanismIndexes': '1'
        },
        {
          'lock': 'Pivot',
          'score': 0,
          'image': pivotThumb,
          'url': '/products/smart-locks/pivot/',
          'imageLarge': pivotThumbLarge,
          'access': 'Keypad (code),RFID badge or fob,Smartphone / Keypad,Smartphone / RFID',
          'power': 'Hardwired',
          'locking': 'Motorized',
          'accessIndexes': '2,3,4,5',
          'powerIndexes': '3',
          'mechanismIndexes': '1'
        },
        {
          'lock': 'Versa',
          'score': 0,
          'image': versaThumb,
          'url': '/products/smart-locks/versa/',
          'imageLarge': versaThumbLarge,
          'access': 'Keypad (code),RFID badge or fob,Smartphone / Keypad,Smartphone / RFID',
          'power': 'Battery',
          'locking': 'Manual',
          'accessIndexes': '2,3,4,5',
          'powerIndexes': '2',
          'mechanismIndexes': '2'
        }
      ]
  )
  const [topLock, setTopLock] = useState({
    'lock': 'Aspire',
    'score': aspireScore,
    'image': dummyImage,
    'url': '/products/smart-locks/aspire/',
    'imageLarge': dummyImage,
    'access': 'keypad,rfid badge or fob,smartphone / keypad,Smartphone / RFID',
    'power': 'Battery',
    'locking': 'Motorized'
  });
  const [recLockOne, setRecLockOne] = useState({
    'lock': 'Aspire',
    'score': aspireScore,
    'image': dummyImage,
    'url': '/products/smart-locks/aspire/',
    'imageLarge': dummyImage,
    'access': 'keypad,rfid badge or fob,smartphone / keypad,Smartphone / RFID',
    'power': 'Battery',
    'locking': 'Motorized'
  });
  const [recLockTwo, setRecLockTwo] = useState({
    'lock': 'Aspire',
    'score': aspireScore,
    'image': dummyImage,
    'url': '/products/smart-locks/aspire/',
    'imageLarge': dummyImage,
    'access': 'keypad,rfid badge or fob,smartphone / keypad,Smartphone / RFID',
    'power': 'Battery',
    'locking': 'Motorized'
  });
  const [isMobile, setIsMobile] = useState(false);


  const updateLockScore = (lockName, newScore) => {
    setLockList((prevLockList) =>
        prevLockList.map((lock) =>
            lock.lock === lockName ? {...lock, score: newScore} : {...lock}
        )
    );
  };
  // Function to handle answer selection
  const handleAnswerSelect = (questionId, selectedOption) => {
    if (questionId === 3) {
      setMechanismNumber(selectedOption.answerCount);
    }
    if (questionId === 4) {
      setPowerNumber(selectedOption.answerCount);
    }
    if (questionId === 5) {
      setAccessNumber(selectedOption.answerCount);
    }
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedOption.answerCount,
    }));
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handleEmailSubmit = (e) => {
    e.preventDefault();

    const postSubmit = hubspotSettings.CONTACT_FORM_QUIZ;
    // const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    // const pageUri = isBrowser ? window.location.href : null;
    // const pageName = isBrowser ? document.title : null;
    const body = {
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'quiz_recommendation_1',
          value: topLock.lock
        },
        {
          name: 'quiz_recommendation_2',
          value: recLockOne.lock
        },
        {
          name: 'quiz_recommendation_3',
          value: recLockTwo.lock
        }
      ]
    }
    fetch(postSubmit, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
    })
        // .then(res => res.json())
        .then((res) => {
          setFormSubmitted(true);
          setTimeout(() => {
            showResults({target: null});
          }, 2000)
        })
        .catch(err => {
          alert(err)
        })
  }
  const handleNextQuestion = () => {
    if (answers.hasOwnProperty(currentStep)) {
      if (currentStep !== questionCount) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else {
      alert('Please answer question.');
    }
  };
  const handlePreviousQuestion = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // HANDLE QUESTIONS
  const questionLockStyle = () => {
    let answer = answers[1].toString();
    switch (answer) {
      case '1':
        setOrbitScore((prevCount) => prevCount + 2);
        setPivotScore((prevCount) => prevCount + 1);
        break;
      case '2':
        setCurveScore((prevCount) => prevCount + 1);
        setAspireScore((prevCount) => prevCount + 1);
        break;
      default: // practical
        setCleoScore((prevCount) => prevCount + 1);
        setVersaScore((prevCount) => prevCount + 1);
    }
  };
  const questionLookingToSecure = () => {
    let answer = answers[2].toString();
    if (answer === '1') {
      setAspireScore((prevCount) => prevCount + 1);
      setOrbitScore((prevCount) => prevCount + 1);
      setVersaScore((prevCount) => prevCount + 1);
    } else { // locker
      setCleoScore((prevCount) => prevCount + 1);
      setCurveScore((prevCount) => prevCount + 1);
      setPivotScore((prevCount) => prevCount + 1);
    }
  };
  // Motorized or Manual
  const questionLockingMechanism = () => {
    let answer = answers[3].toString();
    if (answer === '1') {
      setCurveScore((prevCount) => prevCount + 1);
      setAspireScore((prevCount) => prevCount + 1);
      setOrbitScore((prevCount) => prevCount + 1);
      setPivotScore((prevCount) => prevCount + 1);
    } else { // manual
      setVersaScore((prevCount) => prevCount + 4);
      setCleoScore((prevCount) => prevCount + 2);
    }
  };
  const questionPower = () => {
    let answer = answers[4].toString();
    switch (answer) {
      case '1':
        setCleoScore((prevCount) => prevCount + 5);
        break;
      case '2':
        setCurveScore((prevCount) => prevCount + 1);
        setAspireScore((prevCount) => prevCount + 1);
        setOrbitScore((prevCount) => prevCount + 1);
        setVersaScore((prevCount) => prevCount + 1);
        break;
      default: // hardwired
        if (answers[2] !== '1') {
          setPivotScore((prevCount) => prevCount + 5);
        } else {
          setPivotScore((prevCount) => prevCount + 2);
        }
    }
  };
  const questionAccess = () => {
    let answer = answers[5].toString();
    switch (answer) {
      case '1':
        setCleoScore((prevCount) => prevCount + 5);
        break;
      case '2':
        setVersaScore((prevCount) => prevCount + 1);
        setCurveScore((prevCount) => prevCount + 1);
        setPivotScore((prevCount) => prevCount + 1);
        setAspireScore((prevCount) => prevCount + 1);
        break;
      case '3':
        setVersaScore((prevCount) => prevCount + 1);
        setCurveScore((prevCount) => prevCount + 1);
        setPivotScore((prevCount) => prevCount + 1);
        setOrbitScore((prevCount) => prevCount + 1);
        setAspireScore((prevCount) => prevCount + 1);
        break;
      case '4':
        setVersaScore((prevCount) => prevCount + 1);
        setCurveScore((prevCount) => prevCount + 1);
        setPivotScore((prevCount) => prevCount + 1);
        setAspireScore((prevCount) => prevCount + 1);
        break;
      default: // Smartphone / RFID
        setVersaScore((prevCount) => prevCount + 1);
        setCurveScore((prevCount) => prevCount + 1);
        setPivotScore((prevCount) => prevCount + 1);
        setOrbitScore((prevCount) => prevCount + 1);
        setAspireScore((prevCount) => prevCount + 1);
    }
    updateLockScore('Aspire', aspireScore);
    updateLockScore('Cleo', cleoScore);
    updateLockScore('Curve', curveScore);
    updateLockScore('Orbit', orbitScore);
    updateLockScore('Pivot', pivotScore);
    updateLockScore('Versa', versaScore);
  };
  const questionHandle = () => {
    let answer = answers[6].toString();
    if (answer === '1') { //yes
      setCurveScore((prevCount) => prevCount + 1);
      setAspireScore((prevCount) => prevCount + 1);
      setCleoScore((prevCount) => prevCount + 1);
    } else {
      setOrbitScore((prevCount) => prevCount + 2);
      setPivotScore((prevCount) => prevCount + 2);
      setVersaScore((prevCount) => prevCount + 2);
      setAspireScore((prevCount) => prevCount + 2);
      setCleoScore((prevCount) => prevCount + 1);
    }
  };
// END HANDLE QUESTIONS

  const resetQuiz = () => {
    removeQuizSession();
    window.location.reload(true);
  }
  const finishQuiz = () => {
    setAnswers({
      "1": "Design forward",
      "2": "Furniture",
      "3": "Motorized",
      "4": "Battery",
      "5": "Keypad (code)",
      "6": "Yes"
    });
    setActiveQuizClass((prevClass) => (2));
    setCurrentStep(6);
    setShowAnswers(true);
  }
  const setEllipseCoverSize = () => {
    let cover = document.getElementsByClassName('coverer')[0];
    switch (currentStep) {
      case 1:
        cover.style.width = '13px';
        break;
      case 2:
        cover.style.width = '32px';
        break;
      case 3:
        cover.style.width = '50px';
        break;
      case 4:
        cover.style.width = '70px';
        break;
      case 5:
        cover.style.width = '89px';
        break;
      default:
        cover.style.width = '108px';
    }
  };
  const showResults = (e) => {
    // show email form first
    setQuizSession();
    if (e.target !== null) {
      e.preventDefault(); //prevent consultation button from being clicked
    }
    if (showForm || formSubmitted) {
      setShowAnswers(true);
      questionLookingToSecure();
      questionLockStyle();
      questionLockingMechanism();
      questionPower();
      questionAccess();
      if (answers[1].toString() !== '1') {
        questionHandle();
      }
      setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'quiz_finish',
          quiz_recommended_1: document.getElementById('topLock').getAttribute('alt'),
          quiz_recommended_2: document.getElementById('recLockOne').getAttribute('alt'),
          quiz_recommended_3: document.getElementById('recLockTwo').getAttribute('alt')
        });
      }, 1000);

    } else {
      questionLookingToSecure();
      questionLockStyle();
      questionLockingMechanism();
      questionPower();
      questionAccess();
      if (answers[1].toString() !== '1') {
        questionHandle();
      }
      setShowForm(true);
    }

  };
  const startQuiz = () => {
    setCurrentStep(1);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'quiz_start'
    });
  };
  const toggleQuizClass = () => {
    if (currentStep === 0 && !showAnswers) {
      setActiveQuizClass((prevClass) => (0));
    }
    if (currentStep > 0 && !showAnswers) {
      setActiveQuizClass((prevClass) => (1));
    }
    if (showForm) {
      setActiveQuizClass(prevClass => (2));
    }
    if (showAnswers) {
      setActiveQuizClass((prevClass) => (3));
    }
  };
  const setQuizSession = () => {
    sessionStorage.setItem("quiz", "finished");
  }
  const removeQuizSession = () => {
    sessionStorage.removeItem("quiz");
  }
  const checkForQuizSession = () => {
    if (sessionStorage.getItem("quiz")) {
      removeQuizSession();
      resetQuiz();
    }
  }
  const stringToUpperCase = (s) => {
    const capitalized =
        s.charAt(0).toUpperCase()
        + s.slice(1);
    return s;
  }

  // HANDLE CHECKMARKS
  const powerCheckMark = (lockName) => {
    const lock = lockList.find(item => item.lock === lockName);
    return lock.powerIndexes.indexOf(powerNumber.toString()) > -1;
  }
  const mechanismCheckMark = (lockName) => {
    const lock = lockList.find(item => item.lock === lockName);
    return lock.mechanismIndexes.indexOf(mechanismNumber.toString()) > -1;
  }
  const accessCheckMark = (lockName) => {
    const lock = lockList.find(item => item.lock === lockName);
    return lock.accessIndexes.indexOf(accessNumber.toString()) > -1;
  }
  const getAccessBulletLabel = (answer) => {
    return questions[4]?.options?.[answer - 1]?.answer || "Debug Answer";
  }
  const getPowerBulletLabel = (answer) => {
    return questions[3]?.options?.[answer - 1]?.answer || "Debug Answer";
  }
  const getMechanismBulletLabel = (answer) => {
    return questions[2]?.options?.[answer - 1]?.answer || "Debug Answer";
  }

  Object.size = function (obj) {
    let size = 0,
        key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  useEffect(() => { //handle any answer related logic here. deal with discreet answer and handles
    if (answers.hasOwnProperty(1) && currentStep === 1) {
      let elip = document.getElementById('ellipse-6');
      if (answers[1].toString() === '1') {
        setQuestionCount(prevState => (questions.length - 1));
        elip.classList.add('hide');
      } else {
        setQuestionCount(prevState => (questions.length));
        elip.classList.remove('hide');
      }
    }
  }, [answers]);
  useEffect(() => {
    setEllipseCoverSize();
    toggleQuizClass();
  }, [currentStep]);
  useEffect(() => {
    updateLockScore('Aspire', aspireScore);
    updateLockScore('Cleo', cleoScore);
    updateLockScore('Curve', curveScore);
    updateLockScore('Orbit', orbitScore);
    updateLockScore('Pivot', pivotScore);
    updateLockScore('Versa', versaScore);
  }, [aspireScore, cleoScore, curveScore, orbitScore, pivotScore, versaScore]);
  useEffect(() => {
    const topThreeLocks = lockList.map(lock => ({...lock})) // Deep copy
        .sort((a, b) => b.score - a.score)
        .slice(0, 3);
    setTopLock(prevState => ({...prevState, ...(topThreeLocks[0] || {})}));
    setRecLockOne(prevState => ({...prevState, ...(topThreeLocks[1] || {})}));
    setRecLockTwo(prevState => ({...prevState, ...(topThreeLocks[2] || {})}));
    toggleQuizClass();
  }, [lockList, showForm]);
  useEffect(() => {
    setIsMobile(isInnerWidthMobile());
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    checkForQuizSession();
    let emailInput = document.getElementById('email');

    emailInput.addEventListener('input', () => {
      const email = emailInput.value;
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      if (!emailRegex.test(email)) {
        // Display an error message or take other actions
        // emailInput.setCustomValidity('Please enter a valid email address.');
        setIsEmailValid(false);
      } else if(!validateBusinessEmail(email)) {
        // emailInput.setCustomValidity(''); // Reset the validation message
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    });
  }, []);

  return (
      <Layout>
        <Seo
            title={t('quiz_title')}
            description={t('quiz_desc')}
        />
        <div className="quiz">
          <section>
            <div class="container">
              <div className="quiz-container">
                <nav className={currentStep === 0 ? 'nav-back hide' : 'nav-back'}>
                  <button dangerouslySetInnerHTML={{__html: '&lt;&nbsp;' + t('back')}}
                          className={showForm || showAnswers ? 'hide' : ''} disabled={currentStep === 1}
                          onClick={handlePreviousQuestion}/>
                </nav>
                <div className={quizClasses[activeQuizClass]}>
                  <div className={currentStep > 0 ? 'intro hide' : 'intro'}>
                    <div>
                      <StaticImage
                          src="./images/hero-quiz.png"
                          loading={'lazy'}
                          width={567}
                          height={261}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Digilock locks"
                      />
                    </div>
                    <div>
                      <h1 dangerouslySetInnerHTML={{__html: t('intro_headline')}}/>
                      <a role="button"
                         dangerouslySetInnerHTML={{__html: t('get_started')}}
                         hrefLang="en"
                         onClick={startQuiz}
                         href="javascript:void(0)"
                         className={'btn btn--orange'}>
                      </a>
                    </div>
                  </div>
                  <div class="questions-container">
                    {questions.map((q) => (
                        <Question
                            key={q.id}
                            step={currentStep}
                            id={q.id}
                            question={q}
                            onAnswerSelect={handleAnswerSelect}
                            answers={answers}
                        />
                    ))}
                  </div>
                  <div className={showForm ? 'email-form' : 'email-form hide'}>
                    <div className="loading">
                      <span className="loading__dot"></span>
                      <span className="loading__dot"></span>
                      <span class="loading__dot"></span>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: !formSubmitted ? t('calculating_results') : 'Thanks, we\'ll get back to you shortly.'}}/>
                    <form action="#" className={formSubmitted ? 'hide quiz-form' : 'quiz-form ' + context.language}>
                      <label htmlFor="email" dangerouslySetInnerHTML={{__html: t('email') + ':'}}/>
                      <input
                          type="email"
                          id="email"
                          name="email"
                          required={'required'}
                          value={email}
                          onChange={handleEmailChange}
                          placeholder={'Please enter business email'}

                      />
                    </form>
                  </div>
                  <div className={showAnswers ? 'outro' : 'outro hide'}>
                    <div className="panel-container">
                      <div className="recommended-panel">
                        <p dangerouslySetInnerHTML={{__html: t('recommended')}}/>
                        <img
                            id={'topLock'}
                            src={topLock.imageLarge}
                            alt={topLock.lock}
                            width={topLock.image.indexOf('versa') ? '114' : '292'}
                            height={topLock.image.indexOf('versa') ? '241' : '241'}/>
                        <div className="answer-list">
                          <div className="answer-list">
                        <span>
                          <img src={accessCheckMark(topLock.lock) ? blueCheck : circleX} alt="check" width={'10'}
                               height={'10'}/>
                          {getAccessBulletLabel(answers[5])}
                        </span>
                            <span>
                          {/*power*/}
                              <img src={powerCheckMark(topLock.lock) ? blueCheck : circleX} alt="check" width={'10'}
                                   height={'10'}/>
                              {getPowerBulletLabel(answers[4])}</span>
                            <span>
                          <img src={mechanismCheckMark(topLock.lock) ? blueCheck : circleX} alt="check" width={'10'}
                               height={'10'}/>
                              {getMechanismBulletLabel(answers[3])}
                        </span>
                          </div>
                        </div>
                        <a role="button"
                           dangerouslySetInnerHTML={{__html: t('learn_more')}}
                           hrefLang="en"
                           href={topLock.url}
                           className={'btn btn--blue'}>
                        </a>
                      </div>
                      <div className="recommended-panel consider">
                        <span dangerouslySetInnerHTML={{__html: t('might_consider')}}/>
                        <img
                            id={'recLockOne'}
                            src={recLockOne.image}
                            alt={recLockOne.lock}
                            width={recLockTwo.image.indexOf('versa') > -1 ? '99' : '146'}
                            height={recLockTwo.image.indexOf('versa') > -1 ? '198' : '176'}/>
                        <div className="answer-list">
                        <span>
                          <img src={accessCheckMark(recLockOne.lock) ? blueCheck : circleX} alt="check" width={'10'}
                               height={'10'}/>
                          {getAccessBulletLabel(answers[5])}
                        </span>
                          <span>
                          {/*power*/}
                            <img src={powerCheckMark(recLockOne.lock) ? blueCheck : circleX} alt="check" width={'10'}
                                 height={'10'}/>
                            {getPowerBulletLabel(answers[4])}
                          </span>
                          <span>
                          <img src={mechanismCheckMark(recLockOne.lock) ? blueCheck : circleX} alt="check" width={'10'}
                               height={'10'}/>
                            {getMechanismBulletLabel(answers[3])}
                        </span>
                        </div>
                        <a role="button"
                           dangerouslySetInnerHTML={{__html: t('learn_more')}}
                           hrefLang="en"
                           href={recLockOne.url}
                           className={'btn btn--blue'}>
                        </a>
                      </div>
                      <div className="recommended-panel consider">
                        <img
                            id={'recLockTwo'}
                            src={recLockTwo.image}
                            alt={recLockTwo.lock}
                            width={recLockTwo.image.indexOf('versa') > -1 ? '99' : '146'}
                            height={recLockTwo.image.indexOf('versa') > -1 ? '198' : '176'}/>
                        <div className="answer-list">
                        <span>
                          <img src={accessCheckMark(recLockTwo.lock) ? blueCheck : circleX} alt="check" width={'10'}
                               height={'10'}/>
                          {getAccessBulletLabel(answers[5])}
                        </span>
                          <span>
                          {/*power*/}
                            <img src={powerCheckMark(recLockTwo.lock) ? blueCheck : circleX} alt="check" width={'10'}
                                 height={'10'}/>
                            {getPowerBulletLabel(answers[4])}</span>
                          <span>
                          <img src={mechanismCheckMark(recLockTwo.lock) ? blueCheck : circleX} alt="check" width={'10'}
                               height={'10'}/>
                            {getMechanismBulletLabel(answers[3])}
                        </span>
                        </div>
                        <a role="button"
                           dangerouslySetInnerHTML={{__html: t('learn_more')}}
                           hrefLang="en"
                           href={recLockTwo.url}
                           className={'btn btn--blue'}>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                {
                  showAnswers
                      ?
                      <nav className={'nav-answer'}>
                        <div class="show-answer-cta">
                          <a role="button" hrefLang="en" href="/contact/consultation/"
                             dangerouslySetInnerHTML={{__html: t('book_consultation')}}
                             className="btn btn--orange page-brochure-button book-consultatsion"></a>

                          <a role="button" hrefLang="en"
                             href={'/products/compare-locks/?quiz=' + topLock.lock + ',' + recLockOne.lock + ',' + recLockTwo.lock}
                             dangerouslySetInnerHTML={{__html: t('Compare Your Results')}}
                             className="btn btn--darkgrey"/>

                        </div>

                        <button className={'quiz-again'} onClick={resetQuiz}
                                dangerouslySetInnerHTML={{__html: t('take_quiz_again') + '&gt;'}}/>
                      </nav>
                      :
                      <nav className={currentStep === 0 ? 'nav-next hide' : 'nav-next'}>

                        {
                          showForm ?
                              <a role="button"
                                 hrefLang="en"
                                 onClick={handleEmailSubmit}
                                 href="javascript:void(0)"
                                 className={isEmailValid ? 'btn btn--blue submit-email' : 'btn btn--blue submit-email disabled'}
                                 dangerouslySetInnerHTML={{__html: showForm ? t('submit') : t('ok')}}
                              />
                              :
                              <a role="button"
                                 hrefLang="en"
                                 onClick={currentStep === questionCount ? showResults : handleNextQuestion}
                                 href="javascript:void(0)"
                                 className={answers[currentStep] ? 'btn btn--blue' : 'btn btn--blue disabled'}
                                 dangerouslySetInnerHTML={{__html: showForm ? t('submit') : t('ok')}}
                              />
                        }

                        <button
                            onClick={showResults}
                            className={showForm ? 'show-results' : 'show-results hide'}
                            dangerouslySetInnerHTML={{__html: t('results_please')}}
                        />
                        <div className={showForm ? 'ellipses hide' : 'ellipses'}>
                          <div className="coverer" style={{width: covererStep}}/>
                          {
                            questions.map((quest, index) => (
                                <div
                                    key={index}
                                    id={'ellipse-' + (index + 1)}
                                    className={currentStep === (index + 1) ? 'ellipse on' : 'ellipse'}/>
                            ))}
                        </div>
                      </nav>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
