import React from 'react';

const Question = ({ step, id, question, onAnswerSelect }) => {
  if(question) {
    return (
        <div className={step === id ? 'question active' : 'question'} id={'question_'+id}>
          <h2 dangerouslySetInnerHTML={{__html:question.question}} />
          {question.options.map((option, index) => (
              <div className={'answer ' +option.answer} key={index}>
                <label htmlFor={`question-${question.id}-option-${index}`}>
                <input
                    type="radio"
                    name={`question-${question.id}`}
                    id={`question-${question.id}-option-${index}`}
                    onChange={() => onAnswerSelect(question.id, option)}
                />
                  <span className="radio-circle"></span>
                  {option.answer}
                </label>
              </div>
          ))}
        </div>
    );
  }

}

export default Question;
